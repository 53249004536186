import { ClassData } from '@models/class-data';
import { PricingPlan } from './pricing-plan.model';
import { PricingPlanPrice } from './pricing-plan-price.model';
import { StripeSubscriptionStatus } from './stripe-subscription-status.enum';
import { NumberUtils } from '@utils/number-utils';
import { PaymentBillingPeriod } from './payment-billing-period.enum';
import moment from 'moment';

export class UserPricingPlan {
  id: number;

  userId: number;

  pricingPlanId: number;
  pricingPlan?: PricingPlan;

  pricingPlanPriceId: number;
  pricingPlanPrice?: PricingPlanPrice;

  stripeSubscriptionId: string;

  status: StripeSubscriptionStatus;

  canceledAt?: Date;
  cancelAtPeriodEnd: boolean;

  currentPeriodEnd: Date;
  currentPeriodStart: Date;

  endedAt?: Date;

  startDate: Date;

  trialEnd?: Date;
  trialStart?: Date;

  constructor(json: ClassData<UserPricingPlan>) {
    this.id = +json.id;

    this.userId = +json.userId;

    this.pricingPlanId = +json.pricingPlanId;
    if (json.pricingPlan) {
      this.pricingPlan = new PricingPlan(json.pricingPlan);
    }

    this.pricingPlanPriceId = +json.pricingPlanPriceId;
    if (json.pricingPlanPrice) {
      this.pricingPlanPrice = new PricingPlanPrice(json.pricingPlanPrice);
    }

    this.stripeSubscriptionId = json.stripeSubscriptionId;

    this.status = json.status;

    if (json.canceledAt) {
      this.canceledAt = new Date(json.canceledAt);
    }
    this.cancelAtPeriodEnd = json.cancelAtPeriodEnd;

    this.currentPeriodEnd = new Date(json.currentPeriodEnd);
    this.currentPeriodStart = new Date(json.currentPeriodStart);

    if (json.endedAt) {
      this.endedAt = new Date(json.endedAt);
    }

    this.startDate = new Date(json.startDate);

    if (json.trialEnd) {
      this.trialEnd = new Date(json.trialEnd);
    }
    if (json.trialStart) {
      this.trialStart = new Date(json.trialStart);
    }
  }

  isActive(): boolean {
    return [
      StripeSubscriptionStatus.active,
      StripeSubscriptionStatus.trialing,
    ].includes(this.status);
  }

  isUnpaid(): boolean {
    return [
      StripeSubscriptionStatus.past_due,
      StripeSubscriptionStatus.unpaid,
    ].includes(this.status);
  }

  isActivePendingCancellation(): boolean {
    return (
      this.isActive() &&
      this.cancelAtPeriodEnd &&
      moment(this.currentPeriodEnd).isAfter(moment())
    );
  }

  isCanceled(): boolean {
    return this.status === StripeSubscriptionStatus.canceled;
  }

  getPriceFormatted(): string {
    if (!this.pricingPlanPrice) {
      return '-';
    }

    return `${NumberUtils.roundToUpTo2Decimals(
      this.pricingPlanPrice?.priceInCents / 100.0,
    )} €`;
  }

  getBillingPeriodDisplay(): string {
    if (!this.pricingPlanPrice) {
      return '-';
    }

    return PaymentBillingPeriod.getDisplayName(
      this.pricingPlanPrice.billingPeriod,
    );
  }
}

export enum PricingPlanType {
  PRO = 'PRO',
  TEAMS = 'TEAMS',
}

export namespace PricingPlanType {
  export function getDisplayName(pricingPlanType?: PricingPlanType): string {
    switch (pricingPlanType) {
      case PricingPlanType.PRO:
        return 'Pro';
      case PricingPlanType.TEAMS:
        return 'Teams';
      default:
        return '-';
    }
  }
}

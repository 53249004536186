export enum UserRole {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
  PARTNER = 'PARTNER',
  GUEST = 'GUEST',
}

export function isCustomer(userRole: UserRole): boolean {
  return userRole === UserRole.CUSTOMER;
}

export function isAdmin(userRole: UserRole): boolean {
  return userRole === UserRole.ADMIN;
}

export namespace UserRole {
  export function getDisplayName(role?: UserRole): string {
    switch (role) {
      case UserRole.ADMIN:
        return 'APP.USER_ROLE.ADMIN';
      case UserRole.CUSTOMER:
        return 'APP.USER_ROLE.CUSTOMER';
      case UserRole.PARTNER:
        return 'APP.USER_ROLE.PARTNER';
      case UserRole.GUEST:
        return 'APP.USER_ROLE.GUEST';
      default:
        return '';
    }
  }
}

import { ClassData } from '@models/class-data';

export class Account {
  lastLogin?: Date;

  constructor(json: ClassData<Account>) {
    if (json.lastLogin !== undefined) {
      this.lastLogin = new Date(json.lastLogin);
    }
  }
}

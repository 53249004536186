import { ClassData } from '@models/class-data';

export class ShippingData {
  id?: number;

  firstName: string;
  lastName: string;
  phone: string;

  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;
  country: string;

  constructor(json: ClassData<ShippingData>) {
    if (json.id !== undefined) {
      this.id = +json.id;
    }

    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.phone = json.phone;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;
    this.country = json.country;
  }

  isFilled(): boolean {
    return (
      !!this.firstName &&
      !!this.lastName &&
      !!this.phone &&
      !!this.street &&
      !!this.streetNumber &&
      !!this.postalCode &&
      !!this.locality &&
      !!this.country
    );
  }

  getContactData(): string {
    if (this.firstName === '' && this.lastName === '' && this.phone === '') {
      return '-';
    }

    let data = '';
    if (this.firstName !== '' && this.lastName !== '') {
      data = `${this.firstName} ${this.lastName}`;
    } else if (this.firstName !== '') {
      data = this.firstName;
    } else if (this.lastName !== '') {
      data = this.lastName;
    }

    if (this.phone) {
      data += `, ${this.phone}`;
    }

    return data;
  }

  addressToString(addHTMLLineBreakBeforePostalCode?: boolean): string {
    if (addHTMLLineBreakBeforePostalCode) {
      return `${this.street} ${this.streetNumber},<br/> ${this.postalCode} ${this.locality}`;
    }

    return `${this.street} ${this.streetNumber}, ${this.postalCode} ${this.locality}, ${this.country}`;
  }
}

import { ClassData } from '@models/class-data';
import { BillingAddressType } from './billing-address-type.enum';

export class BillingAddress {
  id?: number;

  type: BillingAddressType;

  invoiceRecipient?: string;

  companyName?: string;
  vatNumber?: string;

  street: string;
  streetNumber: string;
  postalCode: string;
  locality: string;

  country?: string;
  federalState?: string;

  constructor(json: ClassData<BillingAddress>) {
    if (json.id) {
      this.id = +json.id;
    }

    this.type = json.type;

    this.invoiceRecipient = json.invoiceRecipient;

    this.companyName = json.companyName;
    this.vatNumber = json.vatNumber;

    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.postalCode = json.postalCode;
    this.locality = json.locality;

    this.country = json.country;
    this.federalState = json.federalState;
  }
}

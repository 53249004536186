import { ClassData } from '@models/class-data';
import { PaymentBillingPeriod } from './payment-billing-period.enum';

export class PricingPlanPrice {
  id: number;

  pricingPlanId: number;

  priceInCents: number;
  billingPeriod: PaymentBillingPeriod;

  isDefault: boolean;

  stripePriceId: string;

  constructor(json: ClassData<PricingPlanPrice>) {
    this.id = +json.id;

    this.pricingPlanId = +json.pricingPlanId;

    this.priceInCents = +json.priceInCents;
    this.billingPeriod = json.billingPeriod;

    this.isDefault = json.isDefault;

    this.stripePriceId = json.stripePriceId;
  }
}

import { ClassData } from '@models/class-data';
import { PricingPlanType } from './pricing-plan-type.enum';
import { PricingPlanPrice } from './pricing-plan-price.model';

export class PricingPlan {
  id: number;

  name: string;
  description: string;

  type: PricingPlanType;

  prices: PricingPlanPrice[];

  //items: PricingPlanItem[];

  order?: number;

  stripeProductId: string;

  constructor(json: ClassData<PricingPlan>) {
    this.id = +json.id;

    this.name = json.name;
    this.description = json.description;

    this.type = json.type;

    this.prices = this.mapPrices(json.prices);

    if (json.order !== undefined) {
      this.order = +json.order;
    }

    this.stripeProductId = json.stripeProductId;
  }

  private mapPrices(prices: PricingPlanPrice[]): PricingPlanPrice[] {
    if (!prices) {
      return [];
    }
    return prices.map((it) => new PricingPlanPrice(it));
  }
}

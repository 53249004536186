export enum PaymentBillingPeriod {
  ONE_TIME = 'ONE_TIME',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export namespace PaymentBillingPeriod {
  export function getDisplayName(billingPeriod?: PaymentBillingPeriod): string {
    switch (billingPeriod) {
      case PaymentBillingPeriod.ONE_TIME:
        return 'APP.BILLING_PERIOD.ONE_TIME';
      case PaymentBillingPeriod.MONTHLY:
        return 'APP.BILLING_PERIOD.MONTHLY';
      case PaymentBillingPeriod.YEARLY:
        return 'APP.BILLING_PERIOD.YEARLY';
      default:
        return '-';
    }
  }
}
